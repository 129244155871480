.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fef3d7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0c0002;
    padding: 0 20px;
  }

  .bottom-bar:hover {
    background: #0c0002;
  }

  .bottom-bar:hover .current-time,
  .bottom-bar:hover .custom-text,
  .bottom-bar:hover .current-date {
    color: #EBE6E3;
  }
  
  .current-date {
    padding-right: 40px;
  }
  
  .bottom-bar-left {
    flex: 1;
    text-align: left;
  }

  .bottom-bar-right {
    flex: 1;
    text-align: right;
  }
  
  .current-time,
  .current-date {
    font-size: 16px;
    color: black;
  }

  .bottom-bar-middle {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .custom-text {
    font-size: 16px;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black
  }

  /* Media Queries (Medium Devices) */
@media screen and (max-width: 1024px) {
  .bottom-bar {
    padding: 0 10px;
  }

  .bottom-bar-left,
  .bottom-bar-right {
    flex: none;
    width: 50%;
    text-align: center;
  }

  .bottom-bar-left {
    order: 1;
  }

  .bottom-bar-middle {
    display: none;
  }

  .bottom-bar-right {
    order: 2;
  }

  .current-time,
  .custom-text,
  .current-date {
    font-size: 14px;
  }

  .current-date {
    text-align: left;
  }
}