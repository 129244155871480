@media (max-width:768px){
  .skill_front{
  margin-bottom:20px !important;
  }
  .skill_back{
  margin-bottom:20px !important;
  }
  .skill_py{
  margin-bottom:20px !important;
  }
  }

  @media (max-width:768px){
    .title{
    font-size:20px !important;
    }
    .text-light{
    font-size:16px !important;
    }
    }
    
@media screen and (max-width: 768px){
  .skill_back, .skill_front, .skill_py{
  display:block !important;
  }
  .container.skill_container{
  display:block !important;
  }
  }

.parent__container {
  width: 100%; /* Adjust the width as per your preference */
  margin: 0 auto; /* Center the container horizontally */
}

.container.skill_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.skill_front,
.skill_back,
.skill_py {
  display: grid;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fef3d7;
  padding: 20px;
  color:#0c0002;
}

.title,
.text-light {
  color: #0c0002;
}

.title-skill {
  color: #fef3d7;
}

.skill_front:hover,
.skill_back:hover,
.skill_py:hover {
  background-color: #0c0002;
}

.skill_front:hover h3,
.skill_front:hover h4,
.skill_front:hover small,
.skill_back:hover h3,
.skill_back:hover h4,
.skill_back:hover small,
.skill_py:hover h3,
.skill_py:hover h4,
.skill_py:hover small {
  color: #EBE6E3; 
  fill: #EBE6E3;
}

.skill_front:hover .skill_details_icon,
.skill_back:hover .skill_details_icon,
.skill_py:hover .skill_details_icon {
  fill: #EBE6E3; /* Change the fill color of the SVG icon to black upon hovering */
}

.front_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-content: center; /* Add this line */
  align-items: center; /* Add this line */
  justify-items: center;
}

.front_skill_details {
  display: flex;
  align-items: center;
}

.skill_details_icon {
  margin-right: 10px;
  fill: #0c0002
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 768px) {
  .parent__container {
    width: 80%;
    padding: 0 20px;
  }

  .container.skill_container {
    grid-template-columns: 1fr;
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 390px) {
  .parent_container {
    width: 80%;
    padding: 0 20px;
  }

  .container.skill_container {
    grid-template-columns: 1fr;
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 1500px) {
  .parent__container {
    width: 80%;
    padding: 0 20px;
  }


  .container.skill_container {
    grid-template-columns: 1fr;
  }
}