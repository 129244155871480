@media screen and (max-width: 768px){
  .left-button, .mid-button, .right-button {
  width: 100% !important;
  display: block !important;
  }
  }
  
.name-animation-container {
  overflow: hidden; /* Hide any overflowing text */
  white-space: nowrap; /* Ensure the text stays on a single line */
  text-overflow: ellipsis; /* Add an ellipsis (...) if the text exceeds the container */
  max-width: 100%; 
  justify-content: center;
  align-items: center;
}

.header-left{
  align-items: center;
  justify-content: center;
}

.headButtons {
  display: flex;
  justify-content: center;
}

.buttons-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  justify-items: center;
  align-items: center;
}

.left-button,
.right-button,
.mid-button {
  width: 200px;
  height: 50px;
  font-size: 25px;
  background-color: #fef3d7;
  font-family: "Times New Roman MT Condensed Bold";
  color: #0c0002;
  border-radius: 7px;
  border: 2px solid #d3c7c7;
  outline: none;
  margin-bottom: 20px;
}

.left-button:hover,
.mid-button:hover,
.right-button:hover {
  background-color: #0c0002;
  color: #fef3d7;
}

/* Media Queries (Medium Devices) */
@media screen and (max-width: 1024px) {
  .test_intro {
    align-items: center;
    justify-items: center;
  }

  .buttons-container {
    grid-template-columns: 1fr; /* Change the grid to a single column */
    grid-gap: 20px; /* Adjust the grid gap as needed */
  }

  .name-animation-container {
    font-size: 25px; /* Adjust the font size for medium devices */
  }

  .left-button,
  .right-button,
  .mid-button {
    width: 100%; /* Set the buttons to 100% width to fill the container */
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 768px) {
  .test_intro {
    align-items: center;
    justify-items: center;
  }

  .buttons-container {
    grid-template-columns: 1fr; /* Change the grid to a single column */
    grid-gap: 10px; /* Adjust the grid gap as needed */
    width: 50%;
  }

  .name-animation-container {
    font-size: 0.5rem; /* Adjust the font size for medium devices */
  }

  .left-button,
  .right-button,
  .mid-button {
    width: 50%; /* Set the buttons to 100% width to fill the container */
    font-size: 0.7rem;
  }
}

/* Media Queries (Extra Small Devices) */
@media screen and (max-width: 390px) {
  .test_intro {
    align-items: center;
    justify-items: center;
  }

  .buttons-container {
    grid-template-columns: 1fr; /* Change the grid to a single column */
    grid-gap: 10px; /* Adjust the grid gap as needed */
    width: 50%;
  }

  .name-animation-container {
    font-size: 0.5rem; /* Adjust the font size for medium devices */
  }

  .left-button,
  .right-button,
  .mid-button {
    width: 50%; /* Set the buttons to 100% width to fill the container */
    font-size: 0.7rem;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
  }
}
