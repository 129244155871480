body {
  margin: 0;
  font-family: "Times New Roman MT Condensed Bold";
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./background/overlay.gif);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  padding-top: 100px;
  padding-bottom: 100px;
}

* {
  color: #fef3d7;
}

@font-face {
  font-family: "Times New Roman MT Condensed Bold";
  src: url("./fonts/custom_font.otf")
    format("truetype");
}

/* Media Query for Small Devices */
@media screen and (max-width: 768px) {
  .about__me {
    width: 90%;
  }

  .about__cards {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }

  .about__card {
    width: 100px;
  }
}
